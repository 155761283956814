<template>
  <div>
    <table-page :config="config" />
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { receptionCity } from '@/utils/public';

export default {
  name: "serviceTeam",
  components: {
    TablePage
  },
  data() {
    return {
      config: {
        /* 表格是否可多选 */
        dataUrl: '/clientUser/serviceTeam/getList?orgShopId=' + this.$route.query.id,
        belongTo: '当前机构：' + this.$route.query.other,
        filter: {
          controls: [
            {
              key: 'teacherSName',
              label: '老师名',
              type: 'text'
            },
            {
              key: 'label',
              label: '标签',
              type: 'text'
            },
            {
              key: 'homePageDisplay',
              label: '是否展示在官网',
              type: 'select',
              config: {
                options: [
                  {
                    text: '否',
                    value: 0
                  },
                  {
                    text: '是',
                    value: 1
                  }
                ]
              }
            },
            {}
          ]
        },
        columns: [
          {
            key: 'teacherSName',
            title: '老师名',
            width: '100px',
          },
          {
            key: 'label',
            title: '标签',
            width: '100px',
          },
          {
            key: 'sort',
            title: '排序',
            width: '100px',
          },
          {
            key: 'briefIntroduction',
            title: '简介',
            width: '300px',
            ellipsis: true
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '80px' : '80px'
          }
        ],

        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        checkbox: true,
        actions: [
          {
            key: 'edit',
            text: '新建服务团队',
            type: 'form',
            permission: '/clientUser/serviceTeam/insert',
            config: {
              color: 'primary',
              title: '新建服务团队',
              // initUrl: "/agency/institutionsEmployee/edit/get",
              submitUrl: "/clientUser/serviceTeam/insert",
              submitText: '保存',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  isDisabled: (row, key) => {
                    return true
                  },
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'teacherSName',
                  label: '老师名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'label',
                  label: '标签',
                  type: 'text',
                  tips: "①多个标签用，隔开②第一个标签会显示在首页③尽量控制在5个字以内",
                  required: true,
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {
                  key: 'picture',
                  label: '图片',
                  required: true,
                  tips: "建议圆形或正方形 ；不超过1mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024000
                  }
                },
                {
                  key: 'briefIntroduction',
                  label: '简介',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                    { min: 1, max: 500, message: '请尽量精简内容，输入500字以内' }
                    ]
                  }
                },
                {
                  key: 'goodAt',
                  label: '擅长方向',
                  type: 'text',
                  config: {
                    rules: [
                      { min: 1, max: 50, message: '请尽量精简内容，输入50字以内' }
                    ]
                  }
                },
                {},
                {
                  key: 'homePageDisplay',
                  label: '显示在官网首页',
                  type: 'radio',
                  required: true,
                  config: {
                    initialValue: 0,
                    options: [
                      {
                        text: '否',
                        value: 0
                      },
                      {
                        text: '是',
                        value: 1
                      },
                    ]
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'edit',
            text: '删除',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/clientUser/serviceTeam/delete',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条服务团队，是否全部删除? `
            },
            config: {
              title: '删除成功',
              submitUrl: '/clientUser/serviceTeam/delete',
              submitText: '确认',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
              ]
            }
          },
        ],
        /* 表格行操作 */
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/clientUser/serviceTeam/update',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/clientUser/serviceTeam/get",
              submitUrl: "/clientUser/serviceTeam/update",
              submitText: '保存',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  isDisabled: (row, key) => {
                    return true
                  },
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'teacherSName',
                  label: '老师名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'label',
                  label: '标签',
                  type: 'text',
                  tips: "①多个标签用，隔开②第一个标签会显示在首页③尽量控制在5个字以内",
                  required: true,
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {
                  key: 'picture',
                  label: '图片',
                  required: true,
                  tips: "建议圆形或正方形 ；不超过1mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024000
                  }
                },
                {
                  key: 'briefIntroduction',
                  label: '简介',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 500, message: '请尽量精简内容，输入500字以内' }
                    ]
                  }
                },
                {
                  key: 'goodAt',
                  label: '擅长方向',
                  type: 'text',
                  config: {
                    rules: [
                      { min: 1, max: 50, message: '请尽量精简内容，输入50字以内' }
                    ]
                  }
                },
                {},
                {
                  key: 'homePageDisplay',
                  label: '显示在官网首页',
                  type: 'radio',
                  required: true,
                  config: {
                    options: [
                      {
                        text: '否',
                        value: 0
                      },
                      {
                        text: '是',
                        value: 1
                      },
                    ]
                  }
                },
                {}
              ]
            }
          }
        ]
      }
    }
  },
  async mounted() {
  },
  onUnmounted() {

  },
  computed: {

  },

  methods: {

  }
}
</script>

<style scoped lang="scss"></style>